<template>
    <div class="popup">
        <div class="my-4">
			<v-btn
				color="red"
				class="mt-1 mb-1 mr-1 white--text"
				fab
				small
				@click.stop="modal = true"
			>
				<v-icon dark>mdi-trash-can-outline</v-icon>
			</v-btn>
        </div>
        <v-dialog
            v-model="modal"
            max-width="300px"
        >
            <v-card>
                    <v-card-title>
                        Na pewno usunąć klienta?
                    </v-card-title>
                    <v-card-text>
						<v-btn
							color="red"
							class="white--text mr-4"
							@click.stop="deleteClient()"
						>
							TAK
						</v-btn>
						<v-btn
							color="gray"
							class="dark--text"
							@click.stop="modal = false"
						>
							NIE
						</v-btn>
					</v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
	props: {
		clientId : {
			type: String
		}
	},
	data() {
		return {
			modal: false,
		}
	},
	methods: {
		deleteClient(){
			this.$axios.delete(`${this.url}api/clients/${this.clientId}`,{
                id: this.clientId
			})
			.then(this.modal = false)
			.catch((error) => {
				throw error
			})
		},
	},
}
</script>