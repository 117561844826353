<template>
    <v-container class="popup">
        <div class="my-4 text-left">
            <v-btn
                color="indigo"
                dark
                medium
                @click="popup = !popup"
            >
                Dodaj klienta
            </v-btn>
    </div>
        <v-dialog
            v-model="popup"
            max-width="500px"
        >
            <v-card>
                <v-form ref="form" >
                    <v-card-title>
                        Dodaj klienta
                    </v-card-title>
                    <v-card-text>
                        <v-radio-group v-model="isFirm" :mandatory="false">
                            <v-radio label="Firma" :value="true"></v-radio>
                            <v-radio label="Osoba fizyczna" :value="false"></v-radio>
                        </v-radio-group>
                        <v-text-field
                            v-model="name"
                            label="Nazwa"
                            :error-messages="nameErrors"
                            clearable
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                        ></v-text-field>
                        <v-text-field
                            v-if="isFirm"
                            v-model="nip"
                            label="NIP"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="nip"
                            label="Adres"
                            clearable
                            v-if="!isFirm"
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            label="Email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"           
                        ></v-text-field>
                    </v-card-text>
                    <p class="font-weight-regular pl-6" v-if="feedback">{{ feedback }}</p>
                    <v-card-actions class="ml-4">
                        <v-btn
                            color="primary"
                            @click="addClient"
                            :disabled="$v.$invalid"
							:loading="isLoaded"
                        >
                            Dodaj
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required, email } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';
export default{
    name: 'Popup',

    data(){
        return{
            isFirm: false,
            popup: false,
            feedback: null,
            name: null,
            email: null,
            nip: null,
			type: null,
			isLoaded: false,
        }
    },
    mixins: [validationMixin, errorMsg],
    validations: {
        name: {
            required,
        },
        email: {
            required,
            email
        },
    },
    methods:{
		resetValidation(){
			this.$refs.form.reset()
			this.$nextTick(() => { this.$v.$reset() })
		},
        addClient(){
            if(!this.$v.$invalid){
				this.isLoaded = true;
                this.$axios.post(`${this.url}api/clients`, {
                    type: this.isFirm ? 0 : 1,
                    name: this.name,
                    email: this.email,
                    nip: this.nip
                }).then(() => {
					this.resetValidation()
					this.popup = false;
					this.isLoaded = false;
                })
            }
    
        }
    }
}
</script>
