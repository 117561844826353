<template>
    <v-container>
		
		<v-text-field
            label="Filtruj"
            single-line
            hide-details
			@input="setClientSearch"
			:value="getClientsSearch"
            class="mb-3"
        ></v-text-field>
        <v-data-table
			:loading="!isDownloaded"
			:search="getClientsSearch"
            :headers="headers"
            :items="clients"
            item-key="id"
            class="elevation-2"
            :hide-default-footer="true"
            :disable-pagination="true"
            :sort-desc="false"
        >
                <template v-slot:item.type="{ item }">

                    {{item.type === 0 ? "Firma" : "Osoba Fizyczna"}}

            </template>
                <template v-slot:item.date="{ item }">

                    {{item.date.split('T')[0]}}

            </template>
            <template v-slot:item.actions="{ item }">
				<div class="d-flex align-center">
				
					<RemoveClinet :clientId='item._id'/>
					<EditClient :client='item' />
				</div>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import io from 'socket.io-client';
import EditClient from '@/components/molecules/EditClient.vue';
import RemoveClinet from '@/components/molecules/RemoveClient.vue';

export default {
    components: {
		EditClient,
		RemoveClinet
	},
	computed: {
		getClientsSearch(){
            return this.$store.getters.getClientsSearch;
        }
	},
    data(){
        return{
			clients: [],
			socket: io(this.url),
			isDownloaded: false,
            headers: [
                { text: 'Typ', value: 'type', align: 'left', sortable: true },
                { text: 'Nazwa', value: 'name', sortable: true },
                { text: 'Dodano', value: 'date', sortable: true },
                { text: 'NIP/Adres', value: 'nip', sortable: true },
                { text: 'Email', value: 'email', sortable: true },
                { text: 'Akcje', value: 'actions', sortable: false },
            ]
        }
	},
    methods:{
		setClientSearch(text){
			this.$store.commit('updateFilterClientsSearch', text);
		},
        getClients(){
            this.$axios.get(this.url + 'api/clients').then(resp => {
				this.clients = resp.data
				this.isDownloaded = true;
            })
		},
	},
    created(){
        this.getClients();

        this.socket.on('newClientAdded', () => {
            this.getClients()
        });

        this.socket.on('clientDeleted', () => {
            this.getClients()
        });
        this.socket.on('clientUpdated', () => {
            this.getClients()
        });
    },
}
</script>
